@font-face {
  font-family: "Europa";
  src: url("resources/fonts/Europa-Light.woff2") format("woff2");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Europa";
  src: url("resources/fonts/Europa-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Europa";
  src: url("resources/fonts/Europa-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
}

body,
button,
input,
textarea {
  font-family: "Europa", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:focus {
  outline: #bbbbbb 1px solid;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
